import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import AppEventTarget, { appEventTypes } from "../events/AppEventTarget";
import { deleteUserToken } from "../helpers/userTokens";
import { rootPaths } from "../../modules/Routing/routesPaths";
import { useQueryCache } from "react-query";
import { setPaymentStatus } from "../helpers/paymentStatus";

const useLogoutRequestHandler = () => {
  const history = useHistory();
  const queryCache = useQueryCache();

  useEffect(() => {
    const handler = async () => {
      deleteUserToken();
      queryCache.clear();
      history.replace(rootPaths.LOGIN);
      setPaymentStatus(null);

      console.info("SUCCESSFULLY LOGGED OUT");
    };

    AppEventTarget.addEventListener(appEventTypes.LOGOUT_REQUEST, handler);
    return () => {
      AppEventTarget.removeEventListener(appEventTypes.LOGOUT_REQUEST, handler);
    };
  }, [history, queryCache]);
};

export default useLogoutRequestHandler;
