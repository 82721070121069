import React, { lazy, Suspense } from "react";
import { withRouter, Switch, Redirect } from "react-router-dom";
import routes from "../Routing/routes";
import AuthorizedRoute from "../Routing/AuthorizedRoute";
import PublicRoute from "../Routing/PublicRoute";
import useTokenChangeHandler from "../../common/eventHandlers/useTokenChangeHandler";
import useLogoutRequestHandler from "../../common/eventHandlers/useLogoutRequestHandler";
import AppContext from "./AppContext";
import GlobalStyle from "../shared/styles/globalStyle";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import Loader from "../shared/components/Loader/Loader";
import ToastContainer from "../shared/components/Toast/ToastContainer";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 600000,
    },
  },
});
const routeComponents = routes.map((routeConfig, index) => {
  let RouteComponent;
  const component = lazy(routeConfig.loader);
  switch (routeConfig.type) {
    case "authed":
      RouteComponent = AuthorizedRoute;
      break;
    case "public":
    default:
      RouteComponent = PublicRoute;
      break;
  }

  return <RouteComponent key={index} component={component} {...routeConfig} />;
});

const App = () => {
  const [token] = useTokenChangeHandler();
  useLogoutRequestHandler();

  const context = {
    userToken: token,
  };

  return (
    <AppContext.Provider value={context}>
      <ToastContainer />
      <ReactQueryCacheProvider queryCache={queryCache}>
        <GlobalStyle />
        <Suspense fallback={<Loader />}>
          <Switch>
            {routeComponents}
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </ReactQueryCacheProvider>
    </AppContext.Provider>
  );
};

export default withRouter(App);
