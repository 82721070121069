import React from "react";
import Header from "./Header";
import Wrapper from "./Wrapper";

const AuthorizedLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <Wrapper>{children}</Wrapper>
    </div>
  );
};

export default AuthorizedLayout;
