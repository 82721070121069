import { rootPaths } from "./routesPaths";

const routes = [
  {
    path: rootPaths.LOGIN,
    exact: true,
    name: "login-page",
    type: "public",
    loader: () => import("../Login"),
  },
  {
    path: rootPaths.HOME,
    exact: true,
    name: "homepage",
    type: "authed",
    loader: () => import("../Home"),
  },
  {
    path: rootPaths.HOME_AFTER_PAYMENT,
    exact: false,
    name: "homepage-after-payment",
    type: "authed",
    loader: () => import("../Home/HomepageAfterPayment"),
  },
  {
    path: rootPaths.LOGOUT,
    exact: true,
    type: "authed",
    loader: () => import("../Logout"),
  },
];

export default routes;
