import styled from "styled-components";
import { media } from "../../../shared/styles/mixins";
import theme from "../../../shared/styles/theme";

export const StyledHeader = styled.div`
  width: 100%;
  height: 64px;
  background: ${theme.colors.darkestBlue};
  box-shadow: ${theme.boxShadows.header};
  h1 {
    color: ${theme.colors.featherGray} !important;
  }
  ${media.down("MOBILE_SMALL")`
height:unset;`}
`;

export const HeaderContentContainer = styled.div`
  height: 100%;
  width: 808px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${media.down("TABLET_SMALL")`
width:80%;  `}
  ${media.down("MOBILE_SMALL")`
width: 83%;
padding-top: 40px;
    padding-bottom: 20px;
`}
`;

export const LoggedUser = styled.div`
  color: ${theme.colors.featherGray};
  margin-right: 40px;
  ${media.down("MOBILE_SMALL")`
margin-right: 17px;
`}
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${theme.colors.featherGray};
  cursor: pointer;
  > svg {
    margin-right: 5px;
    .button_icon_fill {
      fill: ${theme.colors.featherGray};
    }
  }
`;
