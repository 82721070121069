import { createGlobalStyle } from "styled-components";
import { media } from "./mixins";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${theme.colors.darkestBlue};  
    ${media.down("MOBILE_SMALL")`

font-size: 10px;
line-height: 14px;
`}
}
`;

export default GlobalStyle;
