import colors from "./colors";

const theme = {
  colors: colors,
  borderRadii: {
    small: "5px",
    round: "50%",
  },
  boxShadows: {
    black: "0px 4px 20px rgba(0, 0, 0, 0.25)",
    header: "0px 4px 28px rgba(0, 0, 0, 0.05)",
  },
};
export default theme;
