import React from "react";
import ReactBodymovin from "react-bodymovin";
import { LoaderContainer, LoaderWrapper } from "./Loader.components";
import loadingAnimationData from "./loadingAnimationData.json";

const movinOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimationData,
};

const Loader = ({ width = "100px", containerHeight }) => (
  <LoaderContainer containerHeight={containerHeight}>
    <LoaderWrapper width={width}>
      <ReactBodymovin options={movinOptions} />
    </LoaderWrapper>
  </LoaderContainer>
);

export default Loader;
