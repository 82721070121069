export const BREAKPOINTS = {
  MOBILE_ULTRA_SMALL: {
    max: 320,
  },
  MOBILE_TABLE: {
    max: 420,
  },
  MOBILE_SMALL: {
    max: 479.98,
  },
  MOBILE: {
    min: 480,
    max: 767.98,
  },
  TABLET_SMALL: {
    min: 768,
    max: 899.98,
  },
  TABLET: {
    min: 768,
    max: 1199.98,
  },
  TABLET_LARGE: {
    min: 900,
    max: 1199.98,
  },
  LAPTOP: {
    min: 1200,
    max: 1439.98,
  },
  LAPTOP_LARGE: {
    min: 1440,
  },
};
