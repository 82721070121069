import { localStorageKeys } from "./localStorageKeys";
import AppEventTarget from "../events/AppEventTarget";
import TokenChangeEvent from "../events/TokenChangeEvent";
import { addSeconds } from "date-fns";

export const setUserToken = (userToken) => {
  if (userToken) {
    const expireDate = addSeconds(new Date(), userToken.expiresIn);
    const refreshTokenExpireDate = addSeconds(
      new Date(),
      userToken.refreshTokenExpiresIn
    );
    const userData = {
      expireDate: expireDate,
      refreshTokenExpireDate: refreshTokenExpireDate,
      ...userToken,
    };
    localStorage.setItem(localStorageKeys.USER_TOKEN, JSON.stringify(userData));
  } else {
    localStorage.setItem(
      localStorageKeys.USER_TOKEN,
      JSON.stringify(userToken)
    );
  }
  AppEventTarget.dispatchEvent(new TokenChangeEvent(userToken));
};

export const deleteUserToken = () => {
  setUserToken(null);
};

export const getUserToken = () => {
  try {
    const userToken = JSON.parse(
      localStorage.getItem(localStorageKeys.USER_TOKEN) ?? "null"
    );

    if (userToken === null) {
      return null;
    }

    ["expiresIn", "token", "expireDate"].forEach((key) => {
      if (!(key in userToken)) {
        throw new Error(`Missing key: ${key}`);
      }
    });

    userToken.expireDate = new Date(userToken.expireDate);
    if (isNaN(userToken.expireDate)) {
      throw new Error("Invalid expiration date");
    }

    userToken.refreshTokenExpireDate = new Date(
      userToken.refreshTokenExpireDate
    );
    if (isNaN(userToken.refreshTokenExpireDate)) {
      throw new Error("Invalid refresh token expiration date");
    }

    return userToken;
  } catch (err) {
    console.error(`Error parsing user token ${err.message}`);
    deleteUserToken();
    return null;
  }
};
