import React from "react";
import { Redirect, Route } from "react-router-dom";
import PublicLayout from "../App/PublicLayout/PublicLayout";
import useAppContext from "../App/useAppContext";
import { rootPaths } from "./routesPaths";

const PublicRoute = ({ component: Component, ...routeProps }) => {
  const hasToken = !!useAppContext().userToken;

  if (hasToken) {
    return <Redirect to={rootPaths.HOME} />;
  }
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <PublicLayout>
          <Component {...routeComponentProps} />
        </PublicLayout>
      )}
    />
  );
};

export default PublicRoute;
