import { css } from "styled-components";
import { BREAKPOINTS } from "./const";

export const media = {
  up: (breakpoint) => (first, ...interpolations) => {
    if (BREAKPOINTS[breakpoint].min) {
      return css`
        @media (min-width: ${BREAKPOINTS[breakpoint].min}px) {
          ${css(first, ...interpolations)}
        }
      `;
    }

    return css`
      ${css(first, ...interpolations)}
    `;
  },
  down: (breakpoint) => (first, ...interpolations) => {
    if (BREAKPOINTS[breakpoint].max) {
      return css`
        @media (max-width: ${BREAKPOINTS[breakpoint].max}px) {
          ${css(first, ...interpolations)}
        }
      `;
    }

    return css`
      ${css(first, ...interpolations)}
    `;
  },
  only: (breakpoint) => (first, ...interpolations) => {
    const minWidthQuery = BREAKPOINTS[breakpoint].min
      ? `(min-width: ${BREAKPOINTS[breakpoint].min}px)`
      : undefined;

    const maxWidthQuery = BREAKPOINTS[breakpoint].max
      ? `(max-width: ${BREAKPOINTS[breakpoint].max}px)`
      : undefined;

    const mediaQuery = [minWidthQuery, maxWidthQuery]
      .filter(Boolean)
      .join(" and ");

    if (mediaQuery) {
      return css`
        @media ${mediaQuery} {
          ${css(first, ...interpolations)}
        }
      `;
    }

    return css`
      ${css(first, ...interpolations)}
    `;
  },
  between: (fromBreakpoint, toBreakpoint) => (first, ...interpolations) => {
    const minWidthQuery = BREAKPOINTS[fromBreakpoint].min
      ? `(min-width: ${BREAKPOINTS[fromBreakpoint].min}px)`
      : undefined;

    const maxWidthQuery = BREAKPOINTS[toBreakpoint].max
      ? `(max-width: ${BREAKPOINTS[toBreakpoint].max}px)`
      : undefined;

    const mediaQuery = [minWidthQuery, maxWidthQuery]
      .filter(Boolean)
      .join(" and ");

    if (mediaQuery) {
      return css`
        @media ${mediaQuery} {
          ${css(first, ...interpolations)}
        }
      `;
    }

    return css`
      ${css(first, ...interpolations)}
    `;
  },
};
