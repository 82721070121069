import { localStorageKeys } from "./localStorageKeys";

export const setPaymentStatus = (status) => {
  localStorage.setItem(localStorageKeys.PAYMENT_STATUS, status);
};

export const getPaymentStatus = () => {
  const status = localStorage.getItem(localStorageKeys.PAYMENT_STATUS);
  return status === "null" ? null : status;
};
