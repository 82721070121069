const colors = {
  white: "#ffffff",
  darkestBlue: "#191725",
  steel: "#A8A7B7",
  featherGray: "#EFEEF8",
  hunterGreen: "#198E7C",
  hunterGreenHover: "#046C5C",
  silver: "#CFCEDB",
  darkBlue: "#363255",
  error: "#FF4B4B",
  mint: "#6CE4A0",
  mintLight: "#BEFFDA",
  redToast: "#FFDCD5",
  gray1: "#333333",
  gray3: "#828282",
  gray4: "#BDBDBD",
  black: "#000000",
};

export default colors;
