import React from "react";
import { ToastContainer as BaseToastContainer } from "react-toastify";
import styled from "styled-components";
import { ReactComponent as CloseToastIcon } from "../../static/icons/close-toast.svg";
import { media } from "../../styles/mixins";
import theme from "../../styles/theme";

const TOASTIFY_PREFIX = "Toastify";
const TOAST = `${TOASTIFY_PREFIX}__toast`;
const TOAST_CONTAINER = `${TOAST}-container`;
const TOAST_CONTAINER_TOP_CENTER = `${TOAST_CONTAINER}--top-center`;

const TOAST_BODY = `${TOAST}-body`;
const TOAST_SUCCESS = `${TOAST}--success`;
const TOAST_ERROR = `${TOAST}--error`;
const CLOSE_BUTTON = `${TOASTIFY_PREFIX}__close-button`;

const TOAST_MARGIN_BOTTOM = 16;
const ICON_WIDTH = 14;
const ICON_HEIGHT = 14;
const ICON_MARGIN_RIGHT = 8;

const ToastContainer = ({ className, ...props }) => (
  <div className={className}>
    <BaseToastContainer {...props} />
  </div>
);

const CustomCloseContainer = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
`;

export default styled(ToastContainer).attrs({
  position: "top-center",
  hideProgressBar: true,
  autoClose: 5000,
  closeButton: (
    <CustomCloseContainer className="toast-custom-close-button">
      <CloseToastIcon />
    </CustomCloseContainer>
  ),
})`
  .${TOAST_CONTAINER} {
    margin-top: 24px;
    width: 888px;
    padding: 0;
    ${media.down("TABLET_SMALL")`
    width: calc(91.5vw + 4px);


  `}
    ${media.down("MOBILE_SMALL")`
width: calc(96% - 32px);  

`}
  }

  .${TOAST_CONTAINER_TOP_CENTER} {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .${TOAST} {
    width: 100%;
    font-weight: 300;
    font-size: 18px;
    font-family: "Open Sans";
    line-height: 24px;
    letter-spacing: 0.02em;
    color: ${theme.colors.darkestBlue};

    margin-top: ${TOAST_MARGIN_BOTTOM}px;
    padding: 24px;
    border-radius: ${theme.borderRadii.small};
    box-shadow: none;
    ${media.down("MOBILE_SMALL")`
font-size: 14px;
line-height: 19px;

padding: 16px;

`}
  }

  .${TOAST_BODY} {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &::before {
      width: ${ICON_WIDTH}px;
      height: ${ICON_HEIGHT}px;
      margin-right: ${ICON_MARGIN_RIGHT}px;
    }
  }

  .${TOAST_SUCCESS} {
    border: 1px solid ${theme.colors.mint};
    background: ${theme.colors.mintLight};
  }

  .${TOAST_ERROR} {
    background: ${theme.colors.redToast};
    border: 1px solid ${theme.colors.error};
    .close_toast_icon {
      fill: ${theme.colors.error};
    }
  }

  .${CLOSE_BUTTON} {
    align-self: center;
    opacity: 1;
    width: 40px;
    & > svg {
      width: 14px;
      height: 14px;
      transition: color 0.2s ease-in;
    }
  }
`;
