import React from "react";
import { Header1 } from "../../../shared/components/Headers/Headers.components";
import {
  HeaderContentContainer,
  LoggedUser,
  LogoutContainer,
  RightWrapper,
  StyledHeader,
} from "./Header.components";
import { ReactComponent as LogoutIcon } from "../../../shared/static/icons/logout.svg";
import { useHistory } from "react-router";
import { rootPaths } from "../../../Routing/routesPaths";

const Header = () => {
  const history = useHistory();

  const handleLogout = () => {
    history.push(rootPaths.LOGOUT);
  };

  return (
    <StyledHeader>
      <HeaderContentContainer>
        <Header1>iPayMyBill</Header1>
        <RightWrapper>
          <a href="https://www.zzps.co.uk/" target="_blank" rel="noreferrer">
            <LoggedUser>ZZPS</LoggedUser>
          </a>
          <LogoutContainer onClick={handleLogout}>
            <LogoutIcon />
            Log out
          </LogoutContainer>
        </RightWrapper>
      </HeaderContentContainer>
    </StyledHeader>
  );
};

export default Header;
