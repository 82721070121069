import { EventTarget } from "event-target-shim";

class AppEventTarget extends EventTarget {}

export default new AppEventTarget();

export const appEventTypes = {
  TOKEN_CHANGE: "TOKEN_CHANGE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  BRIEF_TAB_CHANGE: "BRIEF_TAB_CHANGE",
  TOOLTIP_OPEN: "TOOLTIP_OPEN",
};
