import styled from "styled-components";
import { media } from "../../styles/mixins";
import theme from "../../styles/theme";

export const Header1 = styled.h1`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.04em;
  color: ${theme.colors.darkestBlue};
  margin: 0;
  ${media.down("MOBILE_SMALL")`
    font-size: 18px;
    line-height: 24px;
    color: ${theme.colors.gray1};
`}
`;

export const Header2 = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${theme.colors.darkestBlue};
  margin: 0;
  ${media.down("MOBILE_SMALL")`
  font-size: 14px;
line-height: 19px;
color: ${theme.colors.gray1};`}
`;

export const Header2Black = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: ${theme.colors.darkestBlue};
  margin: 0;
  ${media.down("MOBILE_SMALL")`
font-size: 14px;
letter-spacing: 0.02em;`}
`;

export const Header2BlackSmall = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: ${theme.colors.darkestBlue};
  margin: 0;
  ${media.down("MOBILE_SMALL")`
font-size: 14px;
letter-spacing: 0.02em;`}
`;
