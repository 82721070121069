import styled from "styled-components";
import theme from "../../styles/theme";

export const LoaderWrapper = styled.div`
  width: ${(p) => (p.width ? p.width : "unset")};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(p) => (p.containerHeight ? p.containerHeight : "unset")};
`;

export const FormLoaderContainer = styled.div`
  position: absolute;
  width: calc(100% + 2px);
  height: 100%;
  top: 0;
  left: 0;
  background: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
