import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthorizedLayout from "../App/AuthorizedLayout/AuthorizedLayout";
import useAppContext from "../App/useAppContext";
import { rootPaths } from "./routesPaths";

const AuthorizedRoute = ({ component: Component, ...routeProps }) => {
  // token means that user is logged in
  const token = !!useAppContext().userToken;
  //change
  if (!token || new Date(token.expiresAt).getTime() < Date.now()) {
    return (
      <Redirect
        to={{
          pathname: rootPaths.LOGIN,
        }}
      />
    );
  }
  return (
    <Route
      {...routeProps}
      render={(routeComponentProps) => (
        <AuthorizedLayout>
          <Component {...routeComponentProps} />
        </AuthorizedLayout>
      )}
    />
  );
};

export default AuthorizedRoute;
