import { useEffect, useState } from "react";

import AppEventTarget, { appEventTypes } from "../events/AppEventTarget";
import { getUserToken } from "../helpers/userTokens";

const useTokenChangeHandler = () => {
  const [token, setToken] = useState(getUserToken());

  useEffect(() => {
    const handler = ({ detail: { userToken } }) => {
      setToken(userToken);
    };

    AppEventTarget.addEventListener(appEventTypes.TOKEN_CHANGE, handler);
    return () => {
      AppEventTarget.removeEventListener(appEventTypes.TOKEN_CHANGE, handler);
    };
  }, []);

  return [token];
};

export default useTokenChangeHandler;
